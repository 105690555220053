.flexItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textEclips {
  white-space: nowrap;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-overflow: inherit;
    width: fit-content;
    white-space: inherit;
  }
}
